.MuiCard-root.MuiPaper-root {
  background-color: white;
  box-shadow: 0 0 2px #ffffff;
  margin-top: calc(10px + 3vmin);
  max-width: calc(600px + 6vmin);
  transition: max-width 0.5s steps(100, end);
  &:hover {
    max-width: calc(650px + 6vmin);
    transition: max-width 0.5s steps(100, start);
    & .MuiCardMedia-root {
      transform: scale(1.05);
      transition: transform 0.5s steps(100, start);
    }
  }
  & .MuiCardMedia-root {
    background-color: black;
    transition: transform 0.5s steps(100, end);
    filter: grayscale(20%);
  }
}
