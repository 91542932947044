.header {
  font-family: 'Martel Sans', sans-serif;
  margin-top: calc(10px + 5vmin);
  line-height: 80%;
  & > span {
    display: block;
    letter-spacing: calc(0.5px + 0.5vmin);
    text-align: center;
    font-family: 'Courier New', Courier, monospace;
    font-size: calc(1px + 3vmin);
    font-style: italic;
    font-weight: lighter;
  }
}

.nav-links {
  display: flex;
  justify-content: space-between;
  margin-top: calc(10px + 5vmin);
  min-width: 50%;
  min-width: calc(100% / 1.5);

  & > a {
    border-top: 1px solid transparent;
    color: rgb(165, 165, 165);
    font-size: calc(10px + 2.5vmin);
    font-size: 60%;
    letter-spacing: calc(0.1px + 0.1vmin);
    text-decoration: none;
    &.selected {
      border-top: 1px solid #626c80;
      color: white;
      transition: border-color 1s ease-out, color 1s ease-out;
    }
  }
}
